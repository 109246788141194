import { toast } from "react-toastify";

export function makeGroup(shuffled_names, size) {
  let groups = [];

  for (let start = 0; start <= shuffled_names.length; start = start + size) {
    let end = start + size;
    if (end > shuffled_names.length) {
      end = shuffled_names.length;
    }

    let get_members = shuffled_names.slice(start, end);

    if (get_members.length > 0) {
      groups.push(get_members);
    }
  }

  return groups;
}

function distributeStudents(students, generatedGroups) {
  let distributedStudents = [];
  let currentIndex = 0;

  generatedGroups.forEach(groupSize => {
    const currentGroup = students.slice(currentIndex, currentIndex + groupSize);
    distributedStudents.push(currentGroup);
    currentIndex += groupSize;
  });

  return distributedStudents;
}


export function shuffleArray(givenArray) {
  for (var i = givenArray.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = givenArray[i];
    givenArray[i] = givenArray[j];
    givenArray[j] = temp;
  }
  return givenArray;
}

export function convertNamesToArray(givenArray) {
  return givenArray
    .split("\n")
    .map((name) => name.trim())
    .filter((name) => name !== "");
}

export function convertArraytoNames(names) {

  let name_values='';
  for (let i = 0; i < names.length; i++) {
    name_values += `${names[i]}\n`;
  }
  return  name_values;
}

export function convertArrayObjectToPersonNames(names) {

  let name_values='';
  for (let i = 0; i < names.length; i++) {
    name_values += `${names[i].person_name}\n`;
  }
  return  name_values;
}


export function recommendNoOfGroupsandMaxStudents(numStudents, groupSize) {
  if (numStudents <= 0 || groupSize <= 0) {
    console.error('Please enter valid values for the number of students and group size.');
    return;
  }

  const numGroups = Math.ceil(numStudents / groupSize);
  const minGroupSize = Math.floor(numStudents / numGroups);
  const extraStudents = numStudents % numGroups;

  let generatedGroups = Array.from({ length: numGroups }, (_, index) => {
    const size = index < extraStudents ? minGroupSize + 1 : minGroupSize;
    return size;
  });

  // Adjust group sizes to ensure no group exceeds the specified size
  generatedGroups = generatedGroups.map(size => Math.min(size, groupSize));

  while (Math.max(...generatedGroups) - Math.min(...generatedGroups) > 1) {
    const maxIndex = generatedGroups.indexOf(Math.max(...generatedGroups));
    const minIndex = generatedGroups.indexOf(Math.min(...generatedGroups));

    generatedGroups[maxIndex]--;
    generatedGroups[minIndex]++;
  }

  return generatedGroups;
}


export function generateGroup(givenArray, group_size) {
  group_size = parseInt(group_size);

  let number_of_students=givenArray.length;

  let recommend=recommendNoOfGroupsandMaxStudents(number_of_students, group_size);
  recommend = recommend.sort((a, b) => b - a);

  console.log('recommend',recommend);

  let shuffled_names = shuffleArray([...givenArray]);

 let distribute= distributeStudents(shuffled_names,recommend);

 console.log('distribute',distribute);

  // let all_groups = makeGroup(shuffled_names, group_size);
  // console.log('all_groups',all_groups);

  return distribute;
}

export function loadDefaultNames() {
  let names = [
    "Olivia",
    "Liam",
    "Sophia",
    "Jackson",
    "Ava",
    "Noah",
    "Isabella",
    "Lucas",
    "Mia",
    "Aiden",
    "Emma",
    "Oliver",
    "Amelia",
    "Elijah",
    "Harper",
    "Ethan",
    "Abigail",
    "Benjamin",
    "Charlotte",
    "Henry"
  ];

  let name_values = "";

  for (let i = 0; i < names.length; i++) {
    name_values += `${names[i]}\n`;
  }

  name_values=name_values.slice(0, -1);

  return name_values;
}

export function getBaseApiURL() {
  return process.env.REACT_APP_API_BASE;
}
export function isTokenPresent() {
  let token = localStorage.getItem("token");

  //console.log('token',token);
  if (token) return true;
  return false;
}

export function getToken() {
  let token = localStorage.getItem("token");
  return token;
}

export function removeTokenAndName() {
  localStorage.removeItem("token");
  localStorage.removeItem("name");
}

export function showToast(
  message = "Data Sucessfully Added",
  type = "success"
) {
  if (type == "success") toast(message);
  else toast.error(message);
}
