import React from "react";
import { Helmet } from "react-helmet";

export default function NotFound() {
  return (
    <>
     <Helmet>
        <title>404 Not Found | Ready Group Go</title>
        <meta name="description" content="404! Page Doesn't Exist" />
        <meta name="author" content="" />
        <meta property="og:title" content="404 Not Found | Ready Group Go" />
        <meta property="og:description" content="404! Page Doesn't Exist" />
        <meta property="og:url" content="https://readygroupgo.com" />
        <meta name="twitter:title" content="404 Not Found | Ready Group G" />
        <meta name="twitter:description" content="404! Page Doesn't Exist" />
        <meta name="twitter:image" content="https://readygroupgo.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
   
    <div className="text-center mt-5 pt-5">
      <h1>404 Not Found</h1>
      <h1></h1>
      <p>Oops! You seem to be lost. This Link Doesn't Exist</p>
    </div>
    </>
  );
}
