import React, { useRef } from "react";
import { Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBaseApiURL, getToken, showToast } from "../../utils/classHelper";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  setReloadClasses,
  setReloadStudents,
  setStudentIdstoBeDeleted,
} from "../../store/slices/classSlice";

export default function ClassesView() {
  const dispatch = useDispatch();
  const classSelectedId = useSelector((state) => state.class.classSelectedId);
  const reloadStudents = useSelector((state) => state.class.reloadStudents);

  const [classAttrNames, setclassAttrNames] = useState({
    attr1: "Attr 1",
    attr2: "Attr 2",
    attr3: "Attr 3",
  });

  const [headerCheckboxValue, setHeaderCheckboxValue] = useState(false);

  const [pasteContent, setPasteContent] = useState(false);

  const [classStudents, setClassStudents] = useState([]);
  const [newRow, setnewRow] = useState({
    name: "",
    attr1: "",
    attr2: "",
    attr3: "",
  });

  const onChangeNewRowData = (event, type) => {
    setnewRow({
      ...newRow,
      [type]: event.target.value,
    });
    // console.log("newRow", newRow);
  };

  const onPasteData = (event) => {
    let pasted_string = event.clipboardData.getData("Text");
    const students = pasted_string.split(/\r?\n/);

    let url = getBaseApiURL() + `/add-student/${classSelectedId}`;

    axios
      .put(
        url,
        {
          type: "array",
          students: students,
        },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((response) => {
        //students

        let students = response.data.class.students;
        setClassStudents(students);
        showToast();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
          }
        }
      });
  };
  const onKeyDownNewRowData = (event) => {
    //make api call

    if (event.key === "Enter") {


      let url = getBaseApiURL() + `/add-student/${classSelectedId}`;

      axios
        .put(
          url,
          {
            person_name: newRow.name,
            attr_1: newRow.attr1,
            attr_2: newRow.attr2,
            attr_3: newRow.attr3,
          },
          { headers: { Authorization: `Bearer ${getToken()}` } }
        )
        .then((response) => {
          //students

          setnewRow({
            name: "",
            attr1: "",
            attr2: "",
            attr3: "",
          });

          const rowFirstInput = document.querySelector(`.data-input-first-field`);

          if (rowFirstInput) {
            rowFirstInput.focus();
          }

          
          let students = response.data.class.students;
          setClassStudents(students);
          showToast();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.errors) {
              //console.log(error.response.data.errors[0]);
            }
          }
        });
    }
    //empty new row data
  };

  const onChangeRowField = (event, type, rowID) => {
    let updatedArray = [...classStudents];

    updatedArray.map((eachStudent) => {
      if (eachStudent.id == rowID) {
        eachStudent[type] = event.target.value;
      }
    });
    setClassStudents(updatedArray);

    // debounced(type, rowID);
  };

  const onUserStopTypingRowField = useDebouncedCallback(
    (event, type, rowID) => {
      // showToast("Student Row updated");
      updateStudentApiCall(event.target.value, type, rowID);
    },
    1000
  );

  const updateStudentApiCall = (value, type, rowID) => {
    let url = getBaseApiURL() + `/update-student/${rowID}`;

    axios
      .post(
        url,
        {
          [type]: value,
        },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((response) => {
        //students

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
          }
        }
      });
  };

  const clickedHeaderCheckbox = (event) => {
    setHeaderCheckboxValue(event.target.checked);

    let updatedArray = [...classStudents];

    updatedArray.map((eachStudent) => {
      eachStudent.is_checked = event.target.checked;
    });
    setClassStudents(updatedArray);
  };

  const clickedRowCheckbox = (event, rowID) => {
    let updatedArray = [...classStudents];

    updatedArray.map((eachStudent) => {
      if (eachStudent.id == rowID) {
        eachStudent.is_checked = event.target.checked;
      }
    });

    setClassStudents(updatedArray);
  };

  const getClassDetails = () => {
    setClassStudents([]);

    let url = getBaseApiURL() + `/class-details/${classSelectedId}`;
    //console.log('url',url);

    axios
      .get(url, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then((response) => {
        //students
        console.log("data", response.data);
        let data = response.data.students;
        data = data.map((obj) => ({ ...obj, is_checked: false }));
        setClassStudents(data);

        //class details
        let class_details = response.data.class_details;
        setclassAttrNames({
          attr1: class_details.attr1,
          attr2: class_details.attr2,
          attr3: class_details.attr3,
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
          }
        }
      });
  };

  useEffect(() => {
    if (classSelectedId > 0) {

      setnewRow({
        name: "",
        attr1: "",
        attr2: "",
        attr3: "",
      });
      
      //console.log(`call class details for class ${classSelectedId}`);
      getClassDetails();
      setHeaderCheckboxValue(false);

      
    }
  }, [classSelectedId]);

  useEffect(() => {
    let studentIdstobeDeleted = [];
    classStudents.map((eachStudent) => {
      if (eachStudent.is_checked) studentIdstobeDeleted.push(eachStudent.id);
    });

    dispatch(setStudentIdstoBeDeleted(studentIdstobeDeleted));
  }, [classStudents]);

  useEffect(() => {
    if (reloadStudents) {
      getClassDetails();
      dispatch(setReloadStudents(false));
    }
  }, [reloadStudents]);



  const inputRefs = useRef(classStudents.map(() => [React.createRef(), React.createRef(), React.createRef(), React.createRef()]));

  
  const handleKeyPress = (event, rowIndex, fieldName) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      // Move the focus to the next row's person_name
      const nextRowIndex = (rowIndex + 1) % classStudents.length;
      const nextRowFirstInput = document.querySelector(`.${fieldName}-row-${nextRowIndex}`);

      if (nextRowFirstInput) {
        nextRowFirstInput.focus();
      }
    }
  };

  
  return (
    <>
      {classSelectedId ? (
        <div className="classes-box">
          <Table hover>
            <thead>
              <tr>
                <th>
                  {" "}
                  <Form.Check
                    type="checkbox"
                    className="header-checkbox"
                    onChange={clickedHeaderCheckbox}
                    checked={headerCheckboxValue}
                  />
                </th>
                <th>Name</th>
                <th>{classAttrNames.attr1}</th>
                <th>{classAttrNames.attr2}</th>
                <th>{classAttrNames.attr3}</th>
              </tr>
            </thead>
            <tbody>
              {classStudents.map((eachStudent, index) => (
                <tr key={index}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      value={index}
                      checked={eachStudent.is_checked ? true : false}
                      onChange={(e) => clickedRowCheckbox(e, eachStudent.id)}
                      className="each-checkbox"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={eachStudent.person_name}
                      className={`person-name person-name-row-${index}`}
                      onKeyDown={(e) => handleKeyPress(e, index, 'person-name')}
                      onChange={(e) => {
                        onChangeRowField(e, "person_name", eachStudent.id);
                        onUserStopTypingRowField(
                          e,
                          "person_name",
                          eachStudent.id
                        );
                      }}
                      onBlur={(event) =>
                        updateStudentApiCall(
                          event.target.value,
                          "person_name",
                          eachStudent.id
                        )
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={eachStudent.attr_1}
                      className={`attr1 attr1-row-${index}`}
                      onKeyDown={(e) => handleKeyPress(e, index, 'attr1')}
                      onChange={(e) => {
                        onChangeRowField(e, "attr_1", eachStudent.id);
                        onUserStopTypingRowField(e, "attr_1", eachStudent.id);
                      }}
                      onBlur={(event) =>
                        updateStudentApiCall(
                          event.target.value,
                          "attr_1",
                          eachStudent.id
                        )
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={eachStudent.attr_2}
                      className={`attr2 attr2-row-${index}`}
                      onKeyDown={(e) => handleKeyPress(e, index, 'attr2')}
                      onChange={(e) => {
                        onChangeRowField(e, "attr_2", eachStudent.id);
                        onUserStopTypingRowField(e, "attr_2", eachStudent.id);
                      }}
                      onBlur={(event) =>
                        updateStudentApiCall(
                          event.target.value,
                          "attr_2",
                          eachStudent.id
                        )
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={eachStudent.attr_3}
                      className={`attr3 attr3-row-${index}`}
                      onKeyDown={(e) => handleKeyPress(e, index, 'attr3')}
                      onChange={(e) => {
                        onChangeRowField(e, "attr_3", eachStudent.id);
                        onUserStopTypingRowField(e, "attr_3", eachStudent.id);
                      }}
                      onBlur={(event) =>
                        updateStudentApiCall(
                          event.target.value,
                          "attr_3",
                          eachStudent.id
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
              <tr key="special">
                <td></td>
                <td>
                  <Form.Control
                    type="text"
                    placeholder="Enter Student Name"
                    className="person-name data-input-first-field"
                    onChange={(event) => onChangeNewRowData(event, "name")}
                    onKeyDown={onKeyDownNewRowData}
                    value={newRow.name}
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    placeholder={"Enter " + classAttrNames.attr1}
                    className="attr1"
                    onChange={(event) => onChangeNewRowData(event, "attr1")}
                    onKeyDown={onKeyDownNewRowData}
                    value={newRow.attr1}
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    placeholder={"Enter " + classAttrNames.attr2}
                    className="attr2"
                    onChange={(event) => onChangeNewRowData(event, "attr2")}
                    onKeyDown={onKeyDownNewRowData}
                    value={newRow.attr2}
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    placeholder={"Enter " + classAttrNames.attr3}
                    className="attr3"
                    onChange={(event) => onChangeNewRowData(event, "attr3")}
                    onKeyDown={onKeyDownNewRowData}
                    value={newRow.attr3}
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          {pasteContent ? (
            <div className="paste-data-section" onPaste={onPasteData}>
              <div className="content">
                Now you can paste your data from any spreadsheet program
                <div
                  className="click-here"
                  onClick={() => setPasteContent(false)}
                >
                  Click Here
                </div>
              </div>
            </div>
          ) : (
            <div className="enable-paste-data-section">
              <div className="content">
                <span
                  className="click-here"
                  onClick={() => setPasteContent(true)}
                >
                  Click Here
                </span>{" "}
                to Paste your data from any spreadsheet Program
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="no-class-selected-section">
          Click any class on the left side bar to see the data
        </div>
      )}
    </>
  );
}
