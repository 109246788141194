import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setForgotPasswordModal,
  setLoginModal,
  setRegisterModal,
} from "../../store/slices/modalSlice";
import { setloggedIn } from "../../store/slices/loggedInSlice";
import { Form } from "react-bootstrap";
import axios from "axios";
import { getBaseApiURL } from "../../utils/classHelper";
import { setName } from "../../store/slices/userSlice";
import { selectSidebarSubOption } from "../../store/slices/sidebarOptionSlice";

export default function ForgotPasswordModal() {
  const dispatch = useDispatch();

  const [emailAddress, setEmailAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [successStatus, setSuccessStatus] = useState(false);
  const [isClickSendForgotLinkButtonDisabled, setIsClickSendForgotLinkButtonDisabled] = useState(false);



  const showForgotPasswordModal = useSelector((state) => {
    console.log("modal", state.modal.forgotPasswordShow);
    return state.modal.forgotPasswordShow;
  });

  const clickedCloseButton = () => {
    dispatch(setForgotPasswordModal(false));
    setSuccessStatus(false);
  };

  const clickedLoginButton = () => {

    setIsClickSendForgotLinkButtonDisabled(true);

    let url = getBaseApiURL() + "/send-reset-password-link";
    setErrorMessage("");

    axios
      .put(url, {
        email: emailAddress,
      })
      .then((response) => {
        let data = response.data;
        console.log("data", data);
        setSuccessStatus(true);

        setIsClickSendForgotLinkButtonDisabled(false);

      })
      .catch((error) => {
        console.log("error", error);

        if (error.response) {
          if (error.response.data.errors) {
            console.log(error.response.data.errors[0]);
            setErrorMessage(error.response.data.errors[0]);
          }
        }

        setIsClickSendForgotLinkButtonDisabled(false);


      });
  };

  return (
    <div>
      {" "}
      <Modal
        show={showForgotPasswordModal}
        onHide={clickedCloseButton}
        backdrop="static"
        keyboard={false}
        className="custom-modal login-register-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="header">
              <h5 className="heading">Forgot Password</h5>
            </div>

            {!successStatus ? (
              <div>
                <br />
                <p>
                  Enter your email Address and we will send you the Reset
                  Password Link
                </p>
                <br />

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Your Email Address"
                    value={emailAddress}
                    onInput={(e) => setEmailAddress(e.target.value)}
                  />
                </Form.Group>

                <button
                  className="btn-brighter-primary submit-button mt-2"
                  type="button"
                  onClick={clickedLoginButton}
                  disabled={isClickSendForgotLinkButtonDisabled}
                >
                  Send Reset Password Link{" "}
                </button>

                {errorMessage ? (
                  <div className="error-message">{errorMessage}</div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <p className="text-center">
                We have Sucessfully send the Reset Password Link to your email.
                Please check your email or junk folder
              </p>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
