import React from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import ShuffleIcon from "../../assets/img/shuffle.svg";
import SaveIcon from "../../assets/img/save.svg";
import { useDispatch, useSelector } from "react-redux";
import { getBaseApiURL, getToken } from "../../utils/classHelper";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import {
  setReloadClasses,
  setSelectedClass,
  setSelectedClassName,
} from "../../store/slices/classSlice";
import plusIcon from "../../assets/img/plus.svg";
import dotsWhite from "../../assets/img/dots-white.svg";
import dotsGray from "../../assets/img/dots-gray.svg";
import editIcon from "../../assets/img/edit.svg";
import deleteIcon from "../../assets/img/delete.svg";

import {
  setAddClassModal,
  setDeleteClassModal,
  setRenameClassModal,
} from "../../store/slices/modalSlice";

export default function ShowClassesSidebar() {
  const dispatch = useDispatch();

  const [myClasses, setMyClasses] = useState([]);

  const classSelectedId = useSelector((state) => state.class.classSelectedId);
  const reloadClasses = useSelector((state) => state.class.reloadClasses);

  console.log("classSelectedId", classSelectedId);

  const clickedAddClassButton = () => {
    dispatch(setAddClassModal(true));
  };
  const clickedClass = (event, classData) => {
    console.log("classData", classData);
    dispatch(setSelectedClass(classData.id));
    dispatch(setSelectedClassName(classData.name));
  };

  const clickedRenameClassButton = () => {
    dispatch(setRenameClassModal(true));
  };

  const clickedDeleteClassButton = () => {
    dispatch(setDeleteClassModal(true));
  };

  const fetchClasses = () => {
    setMyClasses([]);
    dispatch(setReloadClasses(false));
    let url = getBaseApiURL() + "/classes";
    axios
      .get(url, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then((response) => {
        console.log("classes", response.data.classes);
        setMyClasses(response.data.classes);
        if (response.data.classes.length > 0 && classSelectedId == 0) {
          let first_class = response.data.classes[0];
          dispatch(setSelectedClass(first_class.id));
          dispatch(setSelectedClassName(first_class.name));
        }

        // dispatch(setSaveClassModal(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
            // setErrorMessage(error.response.data.errors[0]);
          }
        }
      });
  };

  useEffect(() => {
    //console.log("called");

    fetchClasses();
  }, []);

  useEffect(() => {
    console.log(`reload all classes again`, reloadClasses);

    if (reloadClasses) {
      // console.log(`reload all classes again`);
      fetchClasses();
    }
  }, [reloadClasses]);

  return (
    <div className="custom-form">
      <Form.Group className="show-classes ">
        <Form.Label>Classes</Form.Label>

        <div className="all-classes custom-scrollbar">
          {myClasses.map((eachClass, index) => (
            <div
              key={eachClass.id}
              className={
                "each-class " +
                (classSelectedId == eachClass.id ? "active" : "")
              }
              onClick={(event) => clickedClass(event, eachClass)}
            >
              <div className="title">{eachClass.name}</div>

              <Dropdown>
                <Dropdown.Toggle className="dots">
                  <img src={dotsGray} width={25} className="icon" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    className="rename-class"
                    onClick={clickedRenameClassButton}
                  >
                    <img src={editIcon} width={25} /> Rename Class
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="delete-class"
                    onClick={clickedDeleteClassButton}
                  >
                    <img src={deleteIcon} width={25} /> Delete Class
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ))}
        </div>
        <div className="add-class-button" onClick={clickedAddClassButton}>
          <img src={plusIcon} width={25} /> Add New Class
        </div>
      </Form.Group>
    </div>
  );
}
