import React from "react";

export default function EachSpecialBoxView({ groupName, eachGroup }) {
  let all_names = ``;

  // console.log('inside',eachGroup)
  return (
    //   <div className="each-group">
    //   <h3 className="title">Group {groupName}</h3>
    //   <ul className="names">
    //     {eachGroup.map((name, index) => (
    //       <li key={index}>{name}</li>
    //     ))}
    //   </ul>
    // </div>
    <div className="each-group">
      <h3 className="title">Group {groupName}</h3>
      <ul className="names">
        {eachGroup.map((student, index) => (
          <li key={index}>{student.person_name}</li>
        ))}
      </ul>
    </div>
  );
}
