import React from "react";
import BoxesIcon from "../../assets/img/boxes.svg";
import ListIcon from "../../assets/img/list.svg";
import TextIcon from "../../assets/img/text.svg";
import FullScreenIcon from "../../assets/img/full_screen.svg";

import { useSelector, useDispatch } from "react-redux";
import {
  selectSidebarOption,
  selectSidebarSubOption,
} from "../../store/slices/sidebarOptionSlice";
import { useState } from "react";
import BoxView from "../groups/BoxView";
import TableView from "../groups/TableView";
import ClassesView from "./ClassesView";
import { setDeleteStudentsModal, setEditClassAttributesShow } from "../../store/slices/modalSlice";
import deleteIcon from "../../assets/img/delete.svg";
import editIcon from "../../assets/img/edit.svg";

export default function ContainerClasses() {
  const dispatch = useDispatch();

  //-- load state

  const selectedClassName = useSelector(
    (state) => state.class.classSelectedName
  );

  const studentsIdsToBeDeleted = useSelector(
    (state) => state.class.studentsIdsToBeDeleted
  );
  const classSelectedId = useSelector(
    (state) => state.class.classSelectedId
  );

  console.log('studentsIdsToBeDeleted',studentsIdsToBeDeleted)

  const onClickedDeleteStudentsButton = (event) => {
    if (studentsIdsToBeDeleted.length > 0) {
      dispatch(setDeleteStudentsModal(true));
    }
  };
  const onClickedEditClassAttributeButton = (event) => {
    if (classSelectedId > 0) {
      dispatch(setEditClassAttributesShow(true));
    }
  };

  return (
    <div>
      <div className="classes-section no-visible-scrollbar">
        <div className="header">
          <div className="left-side">
            <h2 className="title">{selectedClassName} Students</h2>
          </div>
          <div className="right-side">

          <button
              className={
                "edit-attributes-button " +
                (classSelectedId > 0 ? "" : "disabled")
              }
              onClick={() => onClickedEditClassAttributeButton()}
            >
              <img src={editIcon} width="20" className="icon"/>  Edit Attributes
            </button>

            <button
              className={
                "delete-students-button " +
                (studentsIdsToBeDeleted.length > 0 ? "" : "disabled")
              }
              onClick={() => onClickedDeleteStudentsButton()}
            >
              <img src={deleteIcon} width="25" className="icon"/> Delete
            </button>

          
          </div>
        </div>
        <ClassesView></ClassesView>
      </div>
    </div>
  );
}
