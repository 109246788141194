import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModal, setRegisterModal } from "../../store/slices/modalSlice";
import { Form } from "react-bootstrap";
import { getBaseApiURL } from "../../utils/classHelper";
import axios from "axios";
import { setloggedIn } from "../../store/slices/loggedInSlice";
import { setName } from "../../store/slices/userSlice";

export default function RegisterModal() {
  const dispatch = useDispatch();

  const showRegisterModal = useSelector((state) => state.modal.registerShow);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const clickedCloseButton = () => {
    dispatch(setRegisterModal(false));
  };

  const clickedLoginButton = () => {
    dispatch(setRegisterModal(false));
    dispatch(setLoginModal(true));
  };

  const clickRegisterButton = () => {
    let url = getBaseApiURL() + "/register";
    setErrorMessage("");

    axios
      .post(url, {
        email: emailAddress,
        password,
        first_name: firstName,
        last_name: lastName,
        confirm_password: confirmPassword,
      })
      .then((response) => {
        let data = response.data;
        let token = data.access_token;
        let name = `${data.user.first_name} ${data.user.last_name}`;

        localStorage.setItem("token", token);
        localStorage.setItem("name", name);

        dispatch(setName(name));
        dispatch(setRegisterModal(false));
        dispatch(setloggedIn(true));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
            setErrorMessage(error.response.data.errors[0]);
          }
        }
      });
  };

  return (
    <div>
      {" "}
      <Modal
        show={showRegisterModal}
        onHide={clickedCloseButton}
        backdrop="static"
        keyboard={false}
        className="custom-modal login-register-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="header">
              <h5 className="heading">Create New Account</h5>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your First Name"
                value={firstName}
                onInput={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="email"
                placeholder="Your Last Name"
                value={lastName}
                onInput={(e) => setLastName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Your Email Address"
                value={emailAddress}
                onInput={(e) => setEmailAddress(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Your Password"
                value={password}
                onInput={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Re-enter to Confirm Password"
                value={confirmPassword}
                onInput={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            <button
              className="btn-brighter-primary submit-button "
              type="button"
              onClick={clickRegisterButton}
            >
              Register
            </button>

            {errorMessage ? (
              <div className="error-message">{errorMessage}</div>
            ) : (
              ""
            )}

            <div className="already-have-account">
              Already Have Account?{" "}
              <div href="" className="click-here" onClick={clickedLoginButton}>
                Sign In
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
