import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setForgotPasswordModal,
  setLoginModal,
  setRegisterModal,
} from "../../store/slices/modalSlice";
import { setloggedIn } from "../../store/slices/loggedInSlice";
import { Form } from "react-bootstrap";
import axios from "axios";
import { getBaseApiURL } from "../../utils/classHelper";
import { setName } from "../../store/slices/userSlice";
import { selectSidebarSubOption } from "../../store/slices/sidebarOptionSlice";

export default function LoginModal() {
  const dispatch = useDispatch();

  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const showLoginModal = useSelector((state) => state.modal.loginShow);
  const loginModelText = useSelector((state) => state.modal.loginText);

  const clickedCloseButton = () => {
    dispatch(setLoginModal(false));
  };

  const clickedSignUpButton = () => {
    dispatch(setLoginModal(false));
    dispatch(setRegisterModal(true));
  };

  const clickedForgotPassword = () => {
    console.log("clcicked");
    dispatch(setLoginModal(false));
    dispatch(setForgotPasswordModal(true));
  };

  const clickedLoginButton = () => {
    let url = getBaseApiURL() + "/login";
    setErrorMessage("");

    if (!emailAddress) {
      setErrorMessage("Email field is required");
    } else if (!password) {
      setErrorMessage("Password field is required");
    } else {
      axios
        .post(url, {
          email: emailAddress,
          password,
        })
        .then((response) => {
          let data = response.data;
          let token = data.access_token;
          let name = `${data.user.first_name} ${data.user.last_name}`;

          localStorage.setItem("token", token);
          localStorage.setItem("name", name);

          dispatch(setName(name));
          dispatch(setLoginModal(false));
          dispatch(setloggedIn(true));
          dispatch(selectSidebarSubOption("boxes"));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.errors) {
              //console.log(error.response.data.errors[0]);

              setErrorMessage(error.response.data.errors[0]);
            }
          }
        });
    }
  };

  return (
    <div>
      {" "}
      <Modal
        show={showLoginModal}
        onHide={clickedCloseButton}
        backdrop="static"
        keyboard={false}
        className="custom-modal login-register-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="header">
              <h5 className="heading">{loginModelText}</h5>
            </div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Your Email Address"
                value={emailAddress}
                onInput={(e) => setEmailAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Your Password"
                value={password}
                onInput={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <div className="text-right">
              <div className="forgot-password" onClick={clickedForgotPassword}>
                Forgot Password
              </div>
            </div>

            <button
              className="btn-brighter-primary submit-button mt-3"
              type="button"
              onClick={clickedLoginButton}
            >
              Login{" "}
            </button>

            {errorMessage ? (
              <div className="error-message">{errorMessage}</div>
            ) : (
              ""
            )}

            <div className="dont-have-account">
              Don’t have an account?{" "}
              <div href="" className="click-here" onClick={clickedSignUpButton}>
                Sign Up
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
