import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setInviteStudentsModal,
  setShareableGroupShow,
} from "../../store/slices/modalSlice";
import { Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import axios from "axios";
import {
  convertArrayObjectToPersonNames,
  convertArraytoNames,
  convertNamesToArray,
  getBaseApiURL,
  getToken,
} from "../../utils/classHelper";
import copyWhiteIcon from "../../assets/img/copy-white.svg";
import studentJoined from "../../assets/img/student-joined.svg";
import EachSpecialBoxView from "../groups/templates/EachSpecialBoxView";
import { saveGroups, setNames } from "../../store/slices/groupSlice";
import QRCode from "react-qr-code";

export default function InviteStudentModal() {
  const dispatch = useDispatch();
  
  const inviteStudentsShow = useSelector(
    (state) => state.modal.inviteStudentsShow
    );
    
    const [keepCallingStudentsAPI, setKeepCallingStudentsAPI] = useState(false);
    const [students, setStudents] = useState([]);
    const [joinCode, setJoinCode] = useState();
    const [ready, setReady] = useState(false);
    
    const website_joining_url = process.env.REACT_APP_JOINING_WEBSITE_URL;
    
    const clickedCloseButton = () => {
      dispatch(setInviteStudentsModal(false));
      
      setTimeout(() => {
        console.log("calling");
        setReady(false);
        setJoinCode("");
        setStudents([]);
      }, 1000);
    };
    
    const clickedReadyButton = () => {
      setReady(true);
      
      let url = getBaseApiURL() + `/create-invite-class`;
      
      axios
      .put(url, {}, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then((response) => {
        console.log(response.data);
        
        let join_code = response.data.join_code;
        setJoinCode(join_code);
        setKeepCallingStudentsAPI(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
            // setErrorMessage(error.response.data.errors[0]);
          }
        }
      });
    };
    
    const getStudents = async () => {
      let url = getBaseApiURL() + `/joined-class-students/${joinCode}`;
      
      axios
      
      .get(url, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then((response) => {
        // console.log(response.data);
        
        let joined_students = response.data.joined_students;
        setStudents(joined_students);
        console.log("joined_students", joined_students);
        // setJoinCode(join_code);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
            // setErrorMessage(error.response.data.errors[0]);
          }
        }
      });
    };
    
    const clickedMakeGroupButton = () => {
      let student_names = convertArrayObjectToPersonNames(students);
      
      console.log("students array", students);
      console.log("student names", student_names);
      
      dispatch(setNames(student_names));
      dispatch(saveGroups([]));
      setKeepCallingStudentsAPI(false);
      dispatch(setInviteStudentsModal(false));
    };
    
    const clickedDeleteName = (eachStudent) => {

      console.log('clicked',eachStudent);
      
      let toRemoveStudent=eachStudent.person_name;
      let filteredStudents = students.filter(obj => obj.person_name !== toRemoveStudent);
      // setStudents(filteredStudents);

      let url = getBaseApiURL() + `/delete-joined-student/${joinCode}/${eachStudent.id}`;
      axios
      .delete(url, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then((response) => {
        // console.log(response.data);
        let joined_students = response.data.joined_students;
        setStudents(joined_students);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
            // setErrorMessage(error.response.data.errors[0]);
          }
        }
      });
      
    }
    
    useEffect(() => {
      let intervalId;
      
      if (keepCallingStudentsAPI) {
        intervalId = setInterval(getStudents, 2000);
      }
      
      // Clean up the interval when the component unmounts or when the button is pressed
      return () => {
        clearInterval(intervalId);
      };
    }, [keepCallingStudentsAPI]);
    
    return (
      <div>
      {" "}
      <Modal
      show={inviteStudentsShow}
      onHide={clickedCloseButton}
      backdrop="static"
      keyboard={false}
      className="custom-modal medium-screen invite-students-modal"
      >
      <Modal.Header>
      {" "}
      <h3 className="heading">Invite Students</h3>
      {ready ? (
        <Dropdown className="close-button-confirm" drop="end">
        <Dropdown.Toggle
        type="button"
        className="btn-close"
        aria-label="Close"
        ></Dropdown.Toggle>
        
        <Dropdown.Menu>
        <Dropdown.Item>
        Are you sure to close? All data will be lost{" "}
        <div className="confirm" onClick={clickedCloseButton}>
        Confirm
        </div>
        </Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        ) : (
          <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={clickedCloseButton}
          ></button>
          )}
          </Modal.Header>
          <Modal.Body>
          {!ready ? (
            <div className="box text-center">
            <p className="description">
            Invite students with a class code when you're ready.
            </p>
            <button
            className="btn-brighter-primary ready-button"
            type="button"
            onClick={clickedReadyButton}
            >
            Ready{" "}
            </button>
            </div>
            ) : (
              <div className="box-2 text-center">
              <div className="sides">
              <div className="double-side">
              </div>
              
              <div className="left-side">
              <h2 className="heading">Join at {website_joining_url}</h2>
              <div className="code">{joinCode}</div>
              </div>
              <div className="double-side">
              <div style={{ background: "white", padding: "16px" }}>
              <QRCode
              value={`https://${website_joining_url}?code=${joinCode}`}
              size={140}
              />
              </div>
              </div>
              </div>
              
              <div className="waiting-for-students">
              Waiting for Students...
              </div>
              <div className="students custom-scrollbar">
              <div className="header">
              <h3>Students Joined</h3>
              <div className="student-joined">
              <img src={studentJoined} className="icon" />
              <div className="number">{students.length}</div>
              </div>
              </div>
              <Row className="body">
              {students.map((eachStudent, index) => (
                <Col key={index} lg={3} md={3} className="each-name" onClick={()=>clickedDeleteName(eachStudent)}>
                {eachStudent.person_name}
                </Col>
                ))}
                </Row>
                </div>
                </div>
                )}
                
                {ready ? (
                  <button
                  className="btn-brighter-primary submit-button"
                  onClick={clickedMakeGroupButton}
                  >
                  Make Group
                  </button>
                  ) : (
                    ""
                    )}
                    </Modal.Body>
                    </Modal>
                    </div>
                    );
                  }
