import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import { getBaseApiURL, showToast } from "../utils/classHelper";
import axios from "axios";

export default function ResetYourPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isClickSetNewPasswordButtonDisabled, setIsClickSetNewPasswordButtonDisabled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { token } = useParams();


  const clickedSetNewPasswordButton = () => {

    setIsClickSetNewPasswordButtonDisabled(true);


    let url = getBaseApiURL() + `/update-password`;
    axios
      .post(url, {
        reset_token: token,
        new_password:password,
        confirm_password:confirmPassword,
      })
      .then((response) => {
        setIsSuccess(true);
        setIsClickSetNewPasswordButtonDisabled(false);
      })
      .catch((error) => {
        setIsClickSetNewPasswordButtonDisabled(false);
        if (error.response) {
          if (error.response.data.errors) {
            console.log(error.response.data.errors[0]);
            showToast(error.response.data.errors[0], "error");
          }
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Reset Your Password | Ready Group Go</title>
        <meta
          name="description"
          content="Reset Your Password | Ready Group Go"
        />
        <meta name="author" content="" />
        <meta
          property="og:title"
          content="Reset Your Password | Ready Group Go"
        />
        <meta
          property="og:description"
          content="Reset Your Password | Ready Group Go"
        />
        <meta property="og:url" content="https://readygroupgo.com" />
        <meta
          name="twitter:title"
          content="Reset Your Password | Ready Group Go"
        />
        <meta
          name="twitter:description"
          content="Reset Your Password | Ready Group Go"
        />
        <meta name="twitter:image" content="https://readygroupgo.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="reset-password-page text-center">
        <a href="/">
          <img src={Logo} alt="" className="logo" />
        </a>

        <h3 className="mt-5">Choose a New Password</h3>
        <div className="box">
          {isSuccess ? (
            <div className="success-message">
              You have Successfully Reset Your Password.
              <br />
              You can login again{" "}
              <a href="/" className="link">
                Homepage
              </a>
            </div>
          ) : (
            <>
              {" "}
              <Form.Group className=" each-field">
                <Form.Control
                  className="student-list"
                  value={password}
                  type="password"
                  onInput={(e) => setPassword(e.target.value)}
                  placeholder="New Password"
                />
              </Form.Group>
              <Form.Group className="each-field">
                <Form.Control
                  className="student-list"
                  type="password"
                  value={confirmPassword}
                  onInput={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm New Password"
                />
              </Form.Group>
              <button className="btn-brighter-primary submit-button" onClick={clickedSetNewPasswordButton} disabled={isClickSetNewPasswordButtonDisabled}
>       
                Set New Password
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
