import {createSlice} from '@reduxjs/toolkit'

export const loggedInSlice = createSlice({
    
    name:'loggedIn',
    initialState:{
            is_logged_in:false
        },

    reducers:{

        setloggedIn: (state, action) =>{

            state.is_logged_in=action.payload
        },
    }
})

export const {setloggedIn} = loggedInSlice.actions
export default loggedInSlice.reducer;