import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddClassModal,
  setDeleteStudentsModal,
  setLoginModal,
  setRegisterModal,
  setSaveClassModal,
} from "../../store/slices/modalSlice";
import { setloggedIn } from "../../store/slices/loggedInSlice";
import { Form } from "react-bootstrap";
import axios from "axios";
import {
  convertNamesToArray,
  getBaseApiURL,
  getToken,
  showToast,
} from "../../utils/classHelper";
import { setName } from "../../store/slices/userSlice";
import { setReloadClasses, setReloadStudents, setStudentIdstoBeDeleted } from "../../store/slices/classSlice";

export default function DeleteSelectedStudentsModal() {
  const dispatch = useDispatch();

  const deleteStudentsShow = useSelector(
    (state) => state.modal.deleteStudentsShow
  );
  const studentsIdsToBeDeleted = useSelector(
    (state) => state.class.studentsIdsToBeDeleted
  );

  //console.log("showSaveClass", showAddClass);

  const [errorMessage, setErrorMessage] = useState();

  const clickedCloseButton = () => {
    dispatch(setDeleteStudentsModal(false));
  };

  const clickedSaveClassButton = () => {
    let url = getBaseApiURL() + "/delete-students";
    setErrorMessage("");

    axios
      .post(
        url,
        {
          students: studentsIdsToBeDeleted,
        },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((response) => {

        console.log(response.data);

        dispatch(setStudentIdstoBeDeleted([]));
        dispatch(setDeleteStudentsModal(false));
        dispatch(setReloadStudents(true));
        showToast('Student(s) Delete Successfully');
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
            setErrorMessage(error.response.data.errors[0]);
          }
        }
      });
  };

  return (
    <div>
      {" "}
      <Modal
        show={deleteStudentsShow}
        onHide={clickedCloseButton}
        backdrop="static"
        keyboard={false}
        className="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="header">
              <h5 className="heading">Delete Students</h5>
              <p className="description">  Are you sure to delete selected {studentsIdsToBeDeleted.length} Students?</p>
            </div>
            <button
              className="btn-brighter-primary danger-button"
              type="button"
              onClick={clickedSaveClassButton}
            >
              Confirm Delete{" "}
            </button>

            {errorMessage ? (
              <div className="error-message">{errorMessage}</div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
