import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoginModal,
  setLoginModalText,
  setRegisterModal,
} from "../../store/slices/modalSlice";
import { Dropdown } from "react-bootstrap";
import { setloggedIn } from "../../store/slices/loggedInSlice";
import { removeTokenAndName } from "../../utils/classHelper";
import {
  selectSidebarOption,
  selectSidebarSubOption,
} from "../../store/slices/sidebarOptionSlice";

function TopNavbar() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.loggedIn.is_logged_in);
  const userName = useSelector((state) => state.user.name);

  const clickedLoginButton = () => {
    dispatch(setLoginModalText("Login To Get Started"));
    dispatch(setLoginModal(true));
  };

  const clickedRegisterButton = () => {
    dispatch(setRegisterModal(true));
  };

  const clickedLoggedOutButton = () => {
    dispatch(setloggedIn(false));
    dispatch(selectSidebarOption("group"));
    dispatch(selectSidebarSubOption("text"));
    removeTokenAndName();
  };

  return (
    <Navbar expand="lg" className="">
      <Container>
        <Navbar.Brand href="#">
          <img src={Logo} alt="" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="m-auto my-2 my-lg-0">
            {/* <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/join">Join Invite</Nav.Link> */}
            {/* <Nav.Link href="#action2">How it Works</Nav.Link>
            <Nav.Link href="#action2">Contact Us</Nav.Link> */}
          </Nav>

          {isLoggedIn ? (
            <Dropdown className="user-dropdown">
              <Dropdown.Toggle>{userName}</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={clickedLoggedOutButton}>
                  Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Nav className="">
              <Button className="btn" onClick={clickedRegisterButton}>
                Sign Up
              </Button>
              <button
                type="button"
                className="btn"
                onClick={clickedLoginButton}
              >
                Login
              </button>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavbar;
