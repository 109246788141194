// export function createSpecialGroups(groupSize, students, type) {
//   const numberOfGroupsTotal = Math.ceil(students.length / groupSize);

import {
  convertNamesToArray,
  generateGroup,
  recommendNoOfGroupsandMaxStudents,
  shuffleArray,
} from "./classHelper";

//   const groups = [
//   ];

//   console.log("------------");
//   console.log("no of students", students.length);
//   console.log("group size ", groupSize);
//   console.log("no of groups", numberOfGroupsTotal);

//   for (let i = 1; i <= numberOfGroupsTotal; i++) {
//     groups[i]=[];
//       }

//   for (let i = 0; i <= [...students].length; i++) {
//     let groupNumber=inWhichGroupCanHaveStudent(students[i][type], type, groups);
//     groups[i].push(students[i]);
//   }

//   // Create a map to store students based on their type
//   // Create a map to store students based on their type

//   console.log('groups',groups);
//   return groups;
// }

// function inWhichGroupCanHaveStudent(value,type, groups) {
//   for (let i = 0; i <= groups.length; i++) {
//     let exists=false;
//         groups[i].map((eachStudent,index)=>{
//                 if(eachStudent[type]==value)
//                 {
//                     exists=true;
//                 }
//         })

//         if(!exists){
//             return i;
//         }
//   }
// }

export function createSpecialGroups(groupSize, students, type, sortingType) {
  // console.log("type", type);
  // console.log("groupSize", groupSize);
  // console.log("students", students);
  if (type == "attr1") type = "attr_1";
  if (type == "attr2") type = "attr_2";
  if (type == "attr3") type = "attr_3";

  if (type == "random") {
    // console.log("all_students", students);
    let groupsa = generateGroup(students, groupSize);
    // console.log("groups", groupsa);
    return groupsa;
  } else {
    // console.log(
    //   "students",
    //   students.length,
    //   "groupsize",
    //   groupSize,
    //   "type",
    //   type
    // );
    let recommend = recommendNoOfGroupsandMaxStudents(
      students.length,
      groupSize
    );
    console.log("recommend", recommend);
    let specials;
    if (sortingType == "uniformly")
      specials = uniformlySpecialDistributeStudents(students, recommend, type);
    else
      specials = diversifySpecialDistributeStudents(students, recommend, type);
    // console.log("specialokay", speciala);
    return specials;
  }
}

function isAlphabetArray(array, type) {
  const alphabetRegex = /^[a-zA-Z]$/;

  for (let i = 0; i < array.length; i++) {
    // Test if the element is a single alphabet character
    if (alphabetRegex.test(array[i][type])) {
      return true;
    }
  }
  return false;
}

function arraySorter(array, type) {
  console.log("array", array);
  if (isAlphabetArray(array, type)) {
    array = array.sort((a, b) => a[type].localeCompare(b[type]));
  } else {
    array = array.slice().sort((a, b) => parseInt(a[type]) - parseInt(b[type]));
  }

  return array;
}

function diversifySpecialDistributeStudents(students, generatedGroups, type) {
  students = shuffleArray(students);
  const studentsCopy = [...students]; // Create a copy of the students array

  let studentsWithTypes = [];
  const studentsWithoutTypes = [];

  studentsCopy.forEach((student) => {
    if (student[type]) {
      studentsWithTypes.push(student);
    } else {
      studentsWithoutTypes.push(student);
    }
  });

  studentsWithTypes = arraySorter(studentsWithTypes, type);
  console.log("studentsWithTypes", studentsWithTypes);
  console.log("studentsWithoutTypes", studentsWithoutTypes);


  let distributedStudents = [];

  generatedGroups.forEach((index) => {
    distributedStudents.push([]);
  });

  let currentIndex = 0;
  studentsWithTypes.forEach((student) => {
    if (
      distributedStudents[currentIndex].length + 1 <=
      generatedGroups[currentIndex]
    ) {
      distributedStudents[currentIndex].push(student);
    }
    currentIndex++;
    if (currentIndex >= generatedGroups.length) currentIndex = 0;
  });

  studentsWithoutTypes.forEach((student) => {
    if (
      distributedStudents[currentIndex].length + 1 <=
      generatedGroups[currentIndex]
    ) {
      distributedStudents[currentIndex].push(student);
    }
    currentIndex++;
    if (currentIndex >= generatedGroups.length) currentIndex = 0;
  });

  // distributedStudents = shuffleArray(distributedStudents);
  console.log("distributedStudents", distributedStudents);
  return distributedStudents;
}
function uniformlySpecialDistributeStudents(students, generatedGroups, type) {
  students = shuffleArray(students);
  const studentsCopy = [...students]; // Create a copy of the students array

  let studentsWithTypes = [];
  const studentsWithoutTypes = [];

  studentsCopy.forEach((student) => {
    if (student[type]) {
      studentsWithTypes.push(student);
    } else {
      studentsWithoutTypes.push(student);
    }
  });

  studentsWithTypes = arraySorter(studentsWithTypes, type);
  console.log("studentsWithTypes", studentsWithTypes);
  console.log("studentsWithoutTypes", studentsWithoutTypes);

  let distributedStudents = [];

  generatedGroups.forEach((index) => {
    distributedStudents.push([]);
  });

  let currentIndex = 0;
  studentsWithTypes.forEach((student) => {
    console.log('currentIndex',currentIndex )
    if (
      distributedStudents[currentIndex].length + 1 <=
      generatedGroups[currentIndex]
    ) {
    } else {
      currentIndex++;
    }
    distributedStudents[currentIndex].push(student);

    // if (currentIndex >= generatedGroups.length) currentIndex = 0;
  });

  console.log("between", distributedStudents);

  studentsWithoutTypes.forEach((student) => {
    console.log('currentIndex',currentIndex )
    if (
      distributedStudents[currentIndex].length + 1 <=
      generatedGroups[currentIndex]
    ) {
    } else {
      currentIndex++;
    }
    distributedStudents[currentIndex].push(student);

    // if (currentIndex >= generatedGroups.length) currentIndex = 0;
  });

  // distributedStudents = shuffleArray(distributedStudents);
  console.log("distributedStudents", distributedStudents);
  return distributedStudents;
}

// function specialDistributeStudents(students, generatedGroups, type) {
//   const studentsCopy = [...students]; // Create a copy of the students array

//   // Separate students by type and create a copy of the array for each type
//   const studentsByType = {};
//   studentsCopy.forEach(student => {
//     const studentType = student[type];
//     if (!studentsByType[studentType]) {
//       studentsByType[studentType] = [];
//     }
//     studentsByType[studentType].push(student);
//   });

//   let distributedStudents = [];
//   let currentIndex = 0;

//   generatedGroups.forEach(groupSize => {
//     const currentGroup = [];
//     for (let i = 0; i < groupSize; i++) {
//       const availableTypes = Object.keys(studentsByType).filter(type => studentsByType[type].length > 0);
//       if (availableTypes.length > 0) {
//         const selectedType = availableTypes[Math.floor(Math.random() * availableTypes.length)];
//         const selectedStudent = studentsByType[selectedType].pop(); // Use pop to remove the selected student
//         currentGroup.push(selectedStudent);
//       } else {
//         // If there are no more students of any type, choose randomly
//         const randomIndex = Math.floor(Math.random() * studentsCopy.length);
//         currentGroup.push(studentsCopy.splice(randomIndex, 1)[0]);
//       }
//     }
//     distributedStudents.push(currentGroup);
//   });

//   return distributedStudents;
// }

// export function createSpecialGroups(groupSize, students, type) {
//   if (type == "attr1") type = "attr_1";
//   if (type == "attr2") type = "attr_2";
//   if (type == "attr3") type = "attr_3";

//   let groups = newCreateSpecialGroups(students, type, groupSize);

//   // let subarrays = divideArrayIntoSubarrays(groups, groupSize);

//   console.log("students", students);
//   console.log("groups", groups);
//   // console.log("groups", subarrays);

//   //   console.log("groups1", groups, "type", type);
//   return groups;
// }

function sortByTypeAscending(array, type) {
  // Check if the type parameter is valid
  if (!["attr_1", "attr_2", "attr_3"].includes(type)) {
    console.error("Invalid type parameter");
    return [];
  }

  // Sort the array based on the specified type in ascending order
  const sortedArray = array.sort((a, b) => {
    return a[type].localeCompare(b[type], undefined, { numeric: true });
  });

  return sortedArray;
}

function newCreateSpecialGroups(students, type, groupSize) {
  const groups = [];
  students = shuffleArray(students, type);

  // console.log("sorted", students);

  const numberOfGroupsTotal = Math.ceil(students.length / groupSize);

  for (let i = 0; i < numberOfGroupsTotal; i++) {
    groups[i] = [];
  }

  for (let i = 0; i < students.length; i++) {
    let wheretoPutStudent = whereToPutStudent(
      type,
      students[i][type],
      groups,
      groupSize
    );
    // console.log("where2", wheretoPutStudent);
    groups[wheretoPutStudent].push(students[i]);
  }

  return groups;
}
// let studentCounter=0;
// for (let i = 0; i < counter.length; i++) {
//   let groupIterator = 0;
//   for (let j = 0; j < counter[i]; j++) {

//     groups[groupIterator].push(students[j]);

//     groupIterator++;
//     if (groupIterator >= numberOfGroupsTotal) {
//       groupIterator = 0;
//     }

//     studentCounter++;
//   }
// }

// console.log("groups", groups, "counter", counter);

function whereToPutStudent(type, typevalue, groups, groupSize) {
  if (type == "random") {
    let checkedAllArray = false;

    while (1) {
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].length == groupSize) {
          continue;
        } else {
          //exists groups
          return i;
        }
      }
      checkedAllArray = true;
    }
  } else {
    let checkedAllArray = false;

    while (1) {
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].length == groupSize) {
          continue;
        }
        if (
          groups[i][type] == typevalue &&
          typevalue != "" &&
          checkedAllArray == false
        ) {
          continue;
          //exists same value
        } else {
          //exists groups
          return i;
        }
      }
      checkedAllArray = true;
    }
  }
}

function typeCounters(array, type) {
  const typeOccurrences = array.reduce((counts, obj) => {
    const objTypeValue = obj[type];
    counts[objTypeValue] = (counts[objTypeValue] || 0) + 1;
    return counts;
  }, {});

  return typeOccurrences;
}

function sortByTypeNumber(array, type, n) {
  // Clone the array to avoid modifying the original array
  let shuffledArray = array.slice();

  // Shuffle the array using Fisher-Yates algorithm
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  // Ensure no next n number of students have the same type
  for (let i = 0; i < shuffledArray.length - n + 1; i++) {
    const currentGroup = shuffledArray.slice(i, i + n);

    // Check if the current group has the same type for all students
    const hasSameType = currentGroup.every(
      (student) => parseInt(student[type]) === parseInt(currentGroup[0][type])
    );

    // If the group has the same type, swap the last student with the next one
    if (hasSameType) {
      const nextIndex = i + n < shuffledArray.length ? i + n : i;
      [shuffledArray[i + n - 1], shuffledArray[nextIndex]] = [
        shuffledArray[nextIndex],
        shuffledArray[i + n - 1],
      ];
    }
  }

  return shuffledArray;
}

function divideArrayIntoSubarrays(arr, chunkSize) {
  const res = [];
  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
}

function roundUpIfDivisibleByHalf(input) {
  if (input % 2 === 0) {
    return input; // Return the number as is if it's divisible by 2
  } else {
    return Math.ceil(input); // Round up to the next integer for decimal values
  }
}

export function getTotalNumberOfStudents(studentNameList) {
  let student_array = convertNamesToArray(studentNameList);
  return student_array.length;
}
export function getGroupSizeOptions(totalStudents) {
  let option_start = 0;
  let option_limit = 0;
  let options_array = [];

  if (totalStudents >= 4) {
    // console.log("inside");
    option_start = 2;
    // console.log("option_limit", totalStudents / 2);
    option_limit = roundUpIfDivisibleByHalf(totalStudents / 2);
    // console.log("option_limit", option_limit);

    while (option_start <= option_limit) {
      options_array.push(option_start);
      option_start++;
    }
  }
  return options_array;
}

export function tableViewAttributesLocationDecider(
  attr1,
  attr2,
  attr3,
  selected
) {
  if (selected == "attr1" || selected == "random") return [attr1, attr2, attr3];
  else if (selected == "attr2") return [attr2, attr1, attr3];
  else if (selected == "attr3") return [attr3, attr1, attr2];
}
