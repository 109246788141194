import {createSlice} from '@reduxjs/toolkit'

export const sidebarOptionSlice = createSlice({
    
    name:'sidebarOption',
    initialState:{
        option:"group",
        subOption:"boxes"
    },

    reducers:{

        selectSidebarOption: (state, action) =>{

            state.option=action.payload
        },
        selectSidebarSubOption: (state, action) =>{

            state.subOption=action.payload
        },
        // saveSelectedAttr: (state, action) =>{
        //     state.classAttr=action.payload
        // }
    }
})

export const {selectSidebarOption,selectSidebarSubOption} = sidebarOptionSlice.actions
export default sidebarOptionSlice.reducer;