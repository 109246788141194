import React from "react";
import { useSelector } from "react-redux";

import { loggedIn } from "../../store/slices/classSlice";
import EachSpecialBoxView from "./templates/EachSpecialBoxView";

export function EachBoxView({ groupName, eachGroup }) {
  let all_names = ``;

  return (
    <div className="each-group">
      <h3 className="title">Group {groupName}</h3>
      <ul className="names">
        {eachGroup.map((name, index) => (
          <li key={index}>{name}</li>
        ))}
      </ul>
    </div>
  );
}


export default function BoxView() {
  const sidebarOption = useSelector((state) => state.sidebarOption.option);

  const sidebarSubOption = useSelector(
    (state) => state.sidebarOption.subOption
  );

  const specialGroups = useSelector((state) => state.group.specialGroups);

  // console.log("specialGroups", specialGroups);

  const groups = useSelector((state) => {
    //console.log("all students,", state.class.groups);
    return state.group.groups;
  });

  // console.log("groups", groups);

  return (
    <div className="group-box">
      {sidebarSubOption == "text"
        ? groups.map((eachGroup, index) => (
            <EachBoxView
              key={index}
              groupName={index + 1}
              eachGroup={eachGroup}
            ></EachBoxView>
          ))
        : ""}

      {sidebarSubOption == "boxes"
        ? specialGroups.map((eachGroup, index) => (
            <EachSpecialBoxView
              key={index}
              groupName={index + 1}
              eachGroup={eachGroup}
            ></EachSpecialBoxView>
          ))
        : ""}

      {(groups.length <= 0 && sidebarSubOption == "text") ||  (specialGroups.length <= 0  && sidebarSubOption == "boxes") ? (
        <div className="no-data-exists">
          Select the Class on the left Sidebar and click on Shuffle to See the
          Groups
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
