import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddClassModal,
  setDeleteStudentsModal,
  setEditClassAttributesShow,
  setLoginModal,
  setRegisterModal,
  setSaveClassModal,
} from "../../store/slices/modalSlice";
import { setloggedIn } from "../../store/slices/loggedInSlice";
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import {
  convertNamesToArray,
  getBaseApiURL,
  getToken,
  showToast,
} from "../../utils/classHelper";
import { setName } from "../../store/slices/userSlice";
import {
  setReloadClasses,
  setReloadStudents,
  setStudentIdstoBeDeleted,
} from "../../store/slices/classSlice";

export default function EditClassAttributesModal() {
  const dispatch = useDispatch();

  const editClassAttributesShow = useSelector(
    (state) => state.modal.editClassAttributesShow
  );
  const classSelectedId = useSelector((state) => state.class.classSelectedId);

  const [attributes, setAttributes] = useState({
    attr1: "",
    attr2: "",
    attr3: "",
  });

  //console.log("showSaveClass", showAddClass);

  const [errorMessage, setErrorMessage] = useState();

  const clickedCloseButton = () => {
    dispatch(setEditClassAttributesShow(false));
  };

  const onChangeAttributeValue = (e, type) => {
    setAttributes({
      ...attributes,
      [type]: e.target.value,
    });
  };

  useEffect(() => {
    if (editClassAttributesShow) {
      let url = getBaseApiURL() + `/class/${classSelectedId}`;
      axios
        .get(url, { headers: { Authorization: `Bearer ${getToken()}` } })
        .then((response) => {
          console.log(response.data);

          let classdata = response.data.class;
          setAttributes({
            attr1: classdata.attr1,
            attr2: classdata.attr2,
            attr3: classdata.attr3,
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.errors) {
              //console.log(error.response.data.errors[0]);
              setErrorMessage(error.response.data.errors[0]);
            }
          }
        });
    }
  }, [editClassAttributesShow]);

  const clickedSaveAttributesButton = () => {
    let url = getBaseApiURL() + `/update-class/${classSelectedId}`;
    setErrorMessage("");
    console.log("fields to be updated", attributes);
    axios
      .post(
        url,
        {
          attr1: attributes.attr1,
          attr2: attributes.attr2,
          attr3: attributes.attr3,
        },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((response) => {
        console.log(response.data);

        dispatch(setEditClassAttributesShow(false));
        dispatch(setReloadStudents(true));

        showToast("Class Attributes Updated Successfully");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
            setErrorMessage(error.response.data.errors[0]);
          }
        }
      });
  };

  return (
    <div>
      {" "}
      <Modal
        show={editClassAttributesShow}
        onHide={clickedCloseButton}
        backdrop="static"
        keyboard={false}
        className="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="header">
              <h5 className="heading">Edit Class Attributes</h5>
              <div className="mt-3">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Attr 1
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Enter Attr 1 Name"
                      value={attributes.attr1}
                      onChange={(e) => onChangeAttributeValue(e, "attr1")}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Attr 2
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Enter Attr 2 Name"
                      value={attributes.attr2}
                      onChange={(e) => onChangeAttributeValue(e, "attr2")}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Attr 3
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Enter Attr 3 Name"
                      value={attributes.attr3}
                      onChange={(e) => onChangeAttributeValue(e, "attr3")}
                    />
                  </Col>
                </Form.Group>
              </div>
            </div>
            <button
              className="btn-brighter-primary submit-button"
              type="button"
              onClick={clickedSaveAttributesButton}
            >
              Save Changes{" "}
            </button>

            {errorMessage ? (
              <div className="error-message">{errorMessage}</div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
