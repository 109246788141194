import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ShuffleIcon from "../../assets/img/shuffle.svg";
import SaveIcon from "../../assets/img/save.svg";
import {
  loadDefaultNames,
  generateGroup,
  convertNamesToArray,
} from "../../utils/classHelper";
import { saveGroups, setNames } from "../../store/slices/groupSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoginModal,
  setLoginModalText,
  setSaveClassModal,
} from "../../store/slices/modalSlice";
import {
  getGroupSizeOptions,
  getTotalNumberOfStudents,
} from "../../utils/groupHelper";

export default function GenerateGroupSidebar() {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.loggedIn.is_logged_in);
  const studentsNames = useSelector((state) => state.group.students);

  const groups = useSelector((state) => state.group.groups);

  const [studentList, setStudentList] = useState("");

  const [optionsGroupsize, setOptionGroupSize] = useState([]);

  useEffect(() => {
    console.log("studentsNames", studentsNames);
    setStudentList(studentsNames);
  }, [studentsNames]);

  useEffect(() => {
    // console.log("optionsGroupsize", optionsGroupsize);
  }, [optionsGroupsize]);

  const [groupSize, setGroupSize] = useState(4);

  const onChangeGroupSize = (e) => {
    setGroupSize(e.target.value);
  };

  const shuffleStudents = () => {
    // console.log("groupSize", groupSize);
    let studentArray = convertNamesToArray(studentList);
    let all_groups = generateGroup(studentArray, groupSize);
    // console.log("studentList", studentList);
    dispatch(saveGroups(all_groups));
  };

  useEffect(() => {
    let total_students = getTotalNumberOfStudents(studentList);
    // console.log("total stidents", total_students);
    setOptionGroupSize(getGroupSizeOptions(total_students));

    if (studentList != "" && groups.length <= 0) {
      shuffleStudents();
    }
  }, [studentList]);

  const clickedSaveClassButton = () => {
    if (isLoggedIn) {
      dispatch(setSaveClassModal(true));
    } else {
      dispatch(
        setLoginModalText("To Save Class, you must Login or Create New Account")
      );
      dispatch(setLoginModal(true));
    }
  };

  const onChangedStudentNames = (names) => {

    console.log('changed');
    setStudentList(names);
    dispatch(setNames(names));
  };

  return (
    <div className="custom-form">
      <Form.Group className="mb-3">
        <Form.Label>Enter Students</Form.Label>
        <Form.Control
          className="student-list custom-scrollbar"
          as="textarea"
          placeholder="Enter Students"
          value={studentList}
          OnChange={(e) => onChangedStudentNames(e.target.value)}
          onInput={(e) => onChangedStudentNames(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Group Size</Form.Label>
        <Form.Select
          className="group-size"
          aria-label="Group Size"
          onChange={onChangeGroupSize}
          value={groupSize}
        >
          <option>Select Group Size</option>

          {optionsGroupsize.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <button
        className="btn-brighter-primary shuffle-button"
        type="button"
        onClick={shuffleStudents}
      >
        <img src={ShuffleIcon} className="shuffle-icon" width={20} />
        Shuffle
      </button>

      <button
        className="mt-3 save-button"
        type="button"
        onClick={clickedSaveClassButton}
      >
        <img src={SaveIcon} className="save-icon" width={20} />
        Save Class
      </button>
    </div>
  );
}
