import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setFeedbackModal,
  setInviteStudentsModal,
  setShareableGroupShow,
} from "../../store/slices/modalSlice";
import { Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import axios from "axios";
import { getBaseApiURL, getToken, showToast } from "../../utils/classHelper";

export default function FeedbackModal() {
  const dispatch = useDispatch();
  const feedbackShow = useSelector((state) => state.modal.feedbackShow);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gradeLevel, setGradeLevel] = useState("High School");
  const [message, setMessage] = useState("");
  const [receiveUpdate, setReceiveUpdate] = useState(false);
  const [isSendFeedbackButtonDisabled, setIsSendFeedbackButtonDisabled]=useState(false);

  const onChangeGradeLevel = (e) => {
    setGradeLevel(e.target.value);
  };

  const clickedCloseButton = () => {
    dispatch(setFeedbackModal(false));
  };

  const clickedSendFeedbackButton = () => {
    let url = getBaseApiURL() + `/submit-feedback`;


    console.log('receiveUpdate',receiveUpdate);
    
    setIsSendFeedbackButtonDisabled(true);

    axios
      .put(url, {
        name,
        email,
        grade_level: gradeLevel,
        message,
        receive_update: receiveUpdate,
      })
      .then((response) => {
        setIsSendFeedbackButtonDisabled(false);
        console.log(response.data);
        showToast("Feedback Successfully Submitted");
        dispatch(setFeedbackModal(false));
      })
      .catch((error) => {
        setIsSendFeedbackButtonDisabled(false);
        if (error.response) {
          if (error.response.data.errors) {
            showToast(error.response.data.errors[0], "error");
          }
        }
      });
  };

  useEffect(() => {
    if (feedbackShow) {
      setName("");
      setEmail("");
      setGradeLevel("High School");
      setMessage("");
      setReceiveUpdate(false);
    }
  }, [feedbackShow]);

  return (
    <div>
      {" "}
      <Modal
        show={feedbackShow}
        onHide={clickedCloseButton}
        backdrop="static"
        keyboard={false}
        className="custom-modal feedback-modal"
      >
        <Modal.Header>
          {" "}
          <h3 className="heading">Feedback Form</h3>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={clickedCloseButton}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="header">
              <h5 className="heading">We'd love to hear from you!</h5>
              <p className="description">
                Ready Group Go is a labor of love made by full-time classroom
                teachers. Please share any feedback or questions here.
              </p>
              <div className="mt-3">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Your Name"
                      value={name}
                      onInput={(e) => setName(e.target.value)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Email
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      value={email}
                      onInput={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Grade Level
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      aria-label="Group Size"
                      onChange={onChangeGradeLevel}
                      value={gradeLevel}
                    >
                      <option value="High School">High School</option>
                      <option value="Middel School">Middel School</option>
                      <option value="Elementary">Elementary</option>
                      <option value="Early Years">Early Years</option>
                      <option value="Other">Other</option>
                    </Form.Select>{" "}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Message
                  </Form.Label>

                  <Col sm={9}>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Message"
                      value={message}
                      onInput={(e) => setMessage(e.target.value)}
                    />
                  </Col>
                </Form.Group>

                <Form.Check
                  inline
                  label="Would you like to receive project updates from us?"
                  name="group1"
                  type="checkbox"
                  defaultChecked={receiveUpdate}
                  onChange={() => setReceiveUpdate(!receiveUpdate)}
                />
              </div>
            </div>
            <button
              className="btn-brighter-primary submit-button"
              type="button"
              onClick={clickedSendFeedbackButton}
              disabled={isSendFeedbackButtonDisabled}
            >
              Send Feedback{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
