import React from "react";
import BoxesIcon from "../../assets/img/boxes.svg";
import ListIcon from "../../assets/img/list.svg";
import TextIcon from "../../assets/img/text.svg";
import FullScreenIcon from "../../assets/img/full_screen.svg";

import { useSelector, useDispatch } from "react-redux";
import {
  selectSidebarOption,
  selectSidebarSubOption,
} from "../../store/slices/sidebarOptionSlice";
import { useState } from "react";
import BoxView from "./BoxView";
import TableView from "./TableView";
import { setShareableGroupShow } from "../../store/slices/modalSlice";
import axios from "axios";
import { getBaseApiURL, getToken } from "../../utils/classHelper";
import { setIinsideGroupSelectedClassShareableId } from "../../store/slices/groupSlice";

export default function Groups() {
  const dispatch = useDispatch();

  //-- load state

  const sidebarOption = useSelector((state) => {
    return state.sidebarOption.option;
  });

  const sidebarSubOption = useSelector((state) => {
    return state.sidebarOption.subOption;
  });

  const isLoggedIn = useSelector((state) => state.loggedIn.is_logged_in);

  const selectedClassShareableId = useSelector(
    (state) => state.group.selectedClassShareableId
  );
  const specialGroups = useSelector((state) => state.group.specialGroups);

  //-- functions
  const handleChange = (event) => {
    dispatch(selectSidebarSubOption(event.currentTarget.value));
  };

  //--conditional classes
  const applySubOptionActiveClass = (option) => {
    if (sidebarSubOption == option) return " active";
    else return "";
  };

  //--conditional renderings

  //--groups
  const renderGroupOptions = () => {
    if (isLoggedIn) {
      return (
        <div className="left-side">
          <h2 className="title">Groups</h2>
          <div className="options">
            <button
              value={"boxes"}
              className={"boxes-button" + applySubOptionActiveClass("boxes")}
              onClick={handleChange}
            >
              <img src={BoxesIcon} width={20} className="icon" />
            </button>
            <button
              value={"list"}
              className={"list-button" + applySubOptionActiveClass("list")}
              onClick={handleChange}
            >
              <img src={ListIcon} width={20} className="icon" />
            </button>
            <button
              value={"text"}
              className={"text-button" + applySubOptionActiveClass("text")}
              onClick={handleChange}
            >
              <img src={TextIcon} width={20} className="icon" />
            </button>
          </div>
        </div>
      );
    }

    // return;
  };

  const clickedShareableGroupButton = () => {

    console.log("clicked");


    if (specialGroups.length > 0 && sidebarSubOption != "text") {
      let url =
        getBaseApiURL() + `/create-sharable-group`;

      axios
        .put(
          url,
          {
            students: specialGroups,
          },

          { headers: { Authorization: `Bearer ${getToken()}` } }
        )
        .then((response) => {

          let new_sharable_id= response.data.shared_class.shareable_id;
          console.log("created",);

          dispatch(setIinsideGroupSelectedClassShareableId(new_sharable_id));
          dispatch(setShareableGroupShow(true));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.errors) {
              //console.log(error.response.data.errors[0]);
            }
          }
        });

  
    }
  };

  const renderGroupFullScreenOption = () => {
    if (isLoggedIn) {
      return (
        <div className="right-side">
          <button
            className={
              "full-screen-button " +
              (specialGroups.length > 0 && sidebarSubOption != "text"
                ? ""
                : "disabled")
            }
            onClick={clickedShareableGroupButton}
          >
            <img src={FullScreenIcon} width={20} className="icon" />
          </button>
        </div>
      );
    }
  };

  const renderDifferentGroupViews = () => {
    if (isLoggedIn) {
      if (sidebarSubOption == "boxes") return <BoxView></BoxView>;
      else if (sidebarSubOption == "list") return <TableView></TableView>;
      else if (sidebarSubOption == "text") return <BoxView></BoxView>;
    } else return <BoxView></BoxView>;
  };

  return (
    <div>
      {sidebarOption == "group" ? (
        <div className="groups-section no-visible-scrollbar">
          <div className="header">
            {renderGroupOptions()}
            {renderGroupFullScreenOption()}
          </div>
          {renderDifferentGroupViews()}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
