import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddClassModal,
  setDeleteClassModal,
  setLoginModal,
  setRegisterModal,
  setSaveClassModal,
} from "../../store/slices/modalSlice";
import { setloggedIn } from "../../store/slices/loggedInSlice";
import { Form } from "react-bootstrap";
import axios from "axios";
import {
  convertNamesToArray,
  getBaseApiURL,
  getToken,
} from "../../utils/classHelper";
import { setName } from "../../store/slices/userSlice";
import { setReloadClasses, setSelectedClass, setSelectedClassName } from "../../store/slices/classSlice";

export default function DeleteClassModal() {
  const dispatch = useDispatch();

  const classSelectedName = useSelector((state) => state.class.classSelectedName);
  const classSelectedId = useSelector((state) => state.class.classSelectedId);
  const deleteClassShow = useSelector((state) => state.modal.deleteClassShow);

  //console.log("showSaveClass", showAddClass);

  const [errorMessage, setErrorMessage] = useState();
  const [newClassName, setNewClassName] = useState();

  const clickedCloseButton = () => {
    dispatch(setDeleteClassModal(false));
  };

  const clickedDeleteClassButton = () => {

    let url = getBaseApiURL() + `/delete-class/${classSelectedId}`;
    setErrorMessage("");

    axios
      .delete(
        url,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((response) => {
        dispatch(setDeleteClassModal(false));
        dispatch(setReloadClasses(true));
        dispatch(setSelectedClass(0));
        dispatch(setSelectedClassName(''));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
            setErrorMessage(error.response.data.errors[0]);
          }
        }
      });
  };

  return (
    <div>
      {" "}
      <Modal
        show={deleteClassShow}
        onHide={clickedCloseButton}
        backdrop="static"
        keyboard={false}
        className="custom-modal save-class-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="header">
              <h5 className="heading">Delete Class</h5>
              <p className="description">
                  Are you sure to delete {classSelectedName} ?
              </p>
            </div>
           
            <button
              className="btn-brighter-primary danger-button"
              type="button"
              onClick={clickedDeleteClassButton}
            >
              Confirm Delete{" "}
            </button>

            {errorMessage ? (
              <div className="error-message">{errorMessage}</div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
