import { configureStore } from '@reduxjs/toolkit'
import classSlice from './slices/classSlice'
import sidebarOptionSlice from './slices/sidebarOptionSlice'
import loggedInSlice from './slices/loggedInSlice'
import modalSlice from './slices/modalSlice'
import userSlice from './slices/userSlice'
import groupSlice from './slices/groupSlice'



export default configureStore({
  reducer: {
    class:classSlice,
    sidebarOption:sidebarOptionSlice,
    loggedIn:loggedInSlice,
    modal:modalSlice,
    user:userSlice,
    group:groupSlice,
  },
  devTools: process.env.NODE_ENV !== 'production', // Enable only in development
})