import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { selectSidebarOption } from "../../store/slices/sidebarOptionSlice";
import { loggedIn } from "../../store/slices/loggedInSlice";
import ShowClassesSidebar from "./ShowClassesSidebar";
import GenerateGroupSidebar from "./GenerateGroupSidebar";
import ChooseClassSideBar from "./ChooseClassSidebar";

export default function Sidebar() {
  const dispatch = useDispatch();

  //-- load state
  const sidebarOption = useSelector((state) => state.sidebarOption.option);
  const sidebarSubOption = useSelector(
    (state) => state.sidebarOption.subOption
  );
  const isLoggedIn = useSelector((state) => state.loggedIn.is_logged_in);

  //-- functions

  const onChangeSidebar = (event) => {
    //console.log("event.target.value", event.target.value);
    dispatch(selectSidebarOption(event.target.value));
  };

  //--conditional classes
  const toggleButtonConditionalClasses = (type) => {
    if (type == sidebarOption) return "active";
    return "";
  };

  //--conditional renderings

  const renderToggleButton = () => {
    if (isLoggedIn)
      return (
        <div className="toggle-button">
          <button
            value={"group"}
            className={toggleButtonConditionalClasses("group")}
            onClick={onChangeSidebar}
          >
            Group
          </button>
          <button
            value={"classes"}
            className={toggleButtonConditionalClasses("classes")}
            onClick={onChangeSidebar}
          >
            Classes
          </button>
        </div>
      );
  };

  const renderSidebar = () => {
    //console.log("sidebarOption", sidebarOption);
    //console.log("sidebarSubOption", sidebarSubOption);

    if (
      sidebarOption == "group" &&
      (sidebarSubOption == "boxes" || sidebarSubOption == "list")
    ) {
      return <ChooseClassSideBar />;
    } else if (sidebarOption == "group" && sidebarSubOption == "text") {
      return <GenerateGroupSidebar />;
    } else if (sidebarOption == "classes") {
      return <ShowClassesSidebar />;
    } else {
      return <GenerateGroupSidebar />;
    }
  };
  return (
    <div className="sidebar-section">
      {renderToggleButton()}
      {renderSidebar()}
    </div>
  );
}
