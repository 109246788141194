import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getBaseApiURL } from "../utils/classHelper";
import axios from "axios";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import EachSpecialBoxView from "./groups/templates/EachSpecialBoxView";
import copyWhiteIcon from "../assets/img/copy-white.svg";
import { Helmet } from "react-helmet";
import { copyToClipboard } from "../utils/commonHelper";

export default function ShareableGroup() {
  const { shareableID } = useParams();

  const navigate = useNavigate()

  const [shareableGroupShow, setShareableGroupShow] = useState(false);
  const [specialGroups, setSpecialGroups] = useState([]);
  const [className1, setClassName] = useState("");

  const [copyButtonText, setCopyButtonText] = useState("Copy URL");
  const [copyText, setCopyText] = useState();

  console.log("shareableID", shareableID);

  //console.log("showSaveClass", showAddClass);

  const website_url = process.env.REACT_APP_WEBSITE_URL;
  const final_url = `${website_url}/group/${shareableID}`;

  const clickedCopyButton = () => {

    copyToClipboard(final_url);
    setCopyButtonText("Copied");
    setTimeout(function () {
      setCopyButtonText("Copy URL");
    }, 500);
  };

  const clickedCloseButton = () => {
    navigate("/");
  };

  const fetchShareableGroups = () => {
    let url = getBaseApiURL() + `/sharable-group/${shareableID}`;

    axios
      .get(url)
      .then((response) => {
        setShareableGroupShow(true);

        // console.log('students',response.data.group.students);

        setSpecialGroups(response.data.group.students);
        setClassName(response.data.class.name);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
          }
        }
      });
  };

  useEffect(() => {
    //console.log("called");

    fetchShareableGroups();
  }, []);

  return (
    <div>
       <Helmet>
        <title>Share Group | Ready Group Go</title>
        <meta name="description" content="You can share your group with others." />
        <meta name="author" content="" />
        <meta property="og:title" content="Share Group | Ready Group Go" />
        <meta property="og:description" content="You can share your group with others." />
        <meta property="og:url" content={final_url} />
        <meta name="twitter:title" content="Share Group | Ready Group Go" />
        <meta name="twitter:description" content="You can share your group with others." />
        <meta name="twitter:image" content={final_url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {" "}
      <Modal
        show={shareableGroupShow}
        onHide={clickedCloseButton}
        backdrop="static"
        keyboard={false}
        className="custom-modal full-screen  sharable-modal"
      >
        <Modal.Header closeButton>
          {" "}
          <h3 className="heading">{className1}</h3>
          <InputGroup className="mb-3 copy-field">
            <Form.Control value={final_url} />
            <Button
              variant="outline-secondary"
              onClick={clickedCopyButton}
            >
              <img src={copyWhiteIcon} width={30} /> {copyButtonText}
            </Button>
          </InputGroup>
        </Modal.Header>
        <Modal.Body>
          <div className="group-box">
            {specialGroups.map((eachGroup, index) => (
              <EachSpecialBoxView
                key={index}
                groupName={index + 1}
                eachGroup={eachGroup}
              ></EachSpecialBoxView>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
