import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { tableViewAttributesLocationDecider } from "../../utils/groupHelper";

export function EachStudent({ student, index }) {
  const classSelectedAttr = useSelector(
    (state) => state.group.selectedAttribute
  );
  const renderValues = tableViewAttributesLocationDecider(
    student.attr_1,
    student.attr_2,
    student.attr_3,
    classSelectedAttr
  );

  return (
    <tr key={index}>
      <td class="td-first">{student.person_name}</td>
      <td
        class={"td-second" + (classSelectedAttr != "random" ? " active" : "")}
      >
        {renderValues[0]}
      </td>
      <td class="td-third">{renderValues[1]}</td>
      <td class="td-forth">{renderValues[2]}</td>
    </tr>
  );
}

export function EachTableView({ groupName, eachGroup }) {
  return (
    <div className="each-table">
      <h5 className="title">Group {groupName}</h5>
      <table>
        <tbody>
          {eachGroup.map((student, index) => (
            <EachStudent student={student} index={index}></EachStudent>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default function TableView() {
  const specialGroups = useSelector((state) => state.group.specialGroups);
  const classSelectedAttr = useSelector(
    (state) => state.group.selectedAttribute
  );
  const classAttributes = useSelector(
    (state) => state.group.selectedClassAttrValues
  );

  let headerValues = tableViewAttributesLocationDecider(
    classAttributes.attr1,
    classAttributes.attr2,
    classAttributes.attr3,
    classSelectedAttr
  );

  return (
    <div className="table-box">
      {specialGroups.length <= 0 ? (
        <div className="no-data-exists">
          Select the Class on the left Sidebar and click on Shuffle to See the
          Groups
        </div>
      ) : (
        <div className="each-table">
          <h5 className="title"></h5>
          <table>
            <thead>
              <th className="td-first">Name</th>
              <th className="td-second">{headerValues[0]}</th>
              <th className="td-third">{headerValues[1]}</th>
              <th className="td-forth">{headerValues[2]}</th>
            </thead>
          </table>
        </div>
      )}

      {specialGroups.map((eachGroup, index) => (
        <EachTableView
          key={index}
          groupName={index + 1}
          eachGroup={eachGroup}
        ></EachTableView>
      ))}
    </div>
  );
}
