import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ShuffleIcon from "../../assets/img/shuffle.svg";
import SaveIcon from "../../assets/img/save.svg";
import { getBaseApiURL, getToken } from "../../utils/classHelper";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setIinsideGroupSelectedClassName,
  setIinsideGroupSelectedClassShareableId,
  setInsideGroupSelectedAttribute,
  setInsideGroupSelectedClassId,
  setInsideGroupSelectedGroupSize,
  setInsideGroupSortingType,
  setSelectedClassAttrValues,
  setSpecialGroups,
} from "../../store/slices/groupSlice";
import {
  createSpecialGroups,
  getGroupSizeOptions,
} from "../../utils/groupHelper";

export default function ChooseClassSideBar() {
  const dispatch = useDispatch();
  const [classes, setClasses] = useState([]);
  const [optionsGroupsize, setOptionsGroupSize] = useState([]);
  const [classAttributes, setClassAttributes] = useState({
    attr1: "",
    attr2: "",
    attr3: "",
  });

  const selectedSortingType = useSelector(
    (state) => state.group.selectedSortingType
  );

  const selectedClassId = useSelector((state) => state.group.selectedClassId);
  const selectedGroupSize = useSelector(
    (state) => state.group.selectedGroupSize
  );
  const selectedAttribute = useSelector(
    (state) => state.group.selectedAttribute
  );

  const renderClassAttributes = () => {
    classes.map((eachClass, index) => {
      // console.log("called2", selectedClassId, eachClass.id);
      if (selectedClassId == eachClass.id) {
        setClassAttributes({
          attr1: eachClass.attr1,
          attr2: eachClass.attr2,
          attr3: eachClass.attr3,
        });

        // dispatch(
        //   setIinsideGroupSelectedClassShareableId(eachClass.shareable_id)
        // );
        dispatch(setIinsideGroupSelectedClassName(eachClass.name));
      }
    });
  };

  const fetchClasses = () => {
    console.log("class fetching");
    let url = getBaseApiURL() + "/classes";
    axios
      .get(url, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then((response) => {
        // console.log("response.data.classes", response.data.classes);
        setClasses(response.data.classes);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
            // setErrorMessage(error.response.data.errors[0]);
          }
        }
      });
  };

  useEffect(() => {
    //console.log("called");

    fetchClasses();
  }, []);

  const onChangeSelectClassButton = (e) => {
    dispatch(setInsideGroupSelectedClassId(e.target.value));
    classes.map((eachClass, index) => {
      if (selectedClassId == eachClass.id) {
        let groupOptions = getGroupSizeOptions(eachClass.no_of_students);
        setOptionsGroupSize(groupOptions);
      }
    });
  };

  const onChangeSelectGroupSizeButton = (e) => {
    dispatch(setInsideGroupSelectedGroupSize(e.target.value));
  };
  const onChangeSelectAttributesButton = (e) => {
    console.log("e.target.value", e.target.value);
    dispatch(setInsideGroupSelectedAttribute(e.target.value));
  };

  const onChangeSortingTypeButton = (e) => {
    console.log("e.target.value", e.target.value);
    dispatch(setInsideGroupSortingType(e.target.value));
  };

  const onClickChooseClassShuffleButton = () => {
    //1- get the data from the selected class

    let url = getBaseApiURL() + `/class-details/${selectedClassId}`;
    //console.log('url',url);

    axios
      .get(url, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then((response) => {
        let students = response.data.students;
        let class_details = response.data.class_details;

        console.log("class1112", class_details);

        dispatch(
          setSelectedClassAttrValues({
            attr1: class_details.attr1,
            attr2: class_details.attr2,
            attr3: class_details.attr3,
          })
        );

        let specialGroups = createSpecialGroups(
          selectedGroupSize,
          students,
          selectedAttribute,
          selectedSortingType
        );

        // console.log("specialGroups", specialGroups);
        dispatch(setSpecialGroups(specialGroups));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
          }
        }
      });

    //2- pass the data and generator
    //3- save the data in the "special groups" groups slice

    //4- add use effect event on the select class that for the first time if the speical groups was empty call this function
  };

  useEffect(() => {
    console.log("class changed", selectedClassId);

    if (selectedClassId == 0 || selectedClassId == "") {
      console.log("zero", selectedClassId);
      setOptionsGroupSize([]);
      dispatch(setInsideGroupSelectedAttribute("random"));
    } else {
      console.log("classes", classes);
      classes.map((eachClass, index) => {
        if (selectedClassId == eachClass.id) {
          let groupOptions = getGroupSizeOptions(eachClass.no_of_students);
          setOptionsGroupSize(groupOptions);
        }
      });
    }
    renderClassAttributes();
  }, [selectedClassId, classes]);

  useEffect(() => {
    if (selectedClassId != 0 || selectedClassId != "") {
      onClickChooseClassShuffleButton();
    }
  }, [selectedClassId, selectedGroupSize, selectedAttribute,selectedSortingType]);

  return (
    <div className="custom-form">
      <Form.Group className="mb-2">
        <Form.Label>Choose Class</Form.Label>
        <Form.Select
          className="choose-class"
          aria-label="Group Size"
          value={selectedClassId}
          onChange={onChangeSelectClassButton}
        >
          <option value="">Select Choose Class</option>
          {classes.map((eachClass, index) => (
            <option key={eachClass.id} value={eachClass.id}>
              {eachClass.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>Group Size</Form.Label>
        <Form.Select
          className="group-size"
          aria-label="Group Size"
          value={selectedGroupSize}
          onChange={onChangeSelectGroupSizeButton}
        >
          {optionsGroupsize.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>Attributes</Form.Label>
        <Form.Select
          className="select-attributes"
          isSearchable={false}
          disabled={!(selectedClassId > 0)}
          value={selectedAttribute}
          onChange={onChangeSelectAttributesButton}
        >
          {/* <option value="">Select Attributes</option> */}

          <option value="random">Random</option>

          {classAttributes.attr1 != "" ? (
            <option value="attr1">{classAttributes.attr1}</option>
          ) : (
            ""
          )}
          {classAttributes.attr2 != "" ? (
            <option value="attr2">{classAttributes.attr2}</option>
          ) : (
            ""
          )}
          {classAttributes.attr3 != "" ? (
            <option value="attr3">{classAttributes.attr3}</option>
          ) : (
            ""
          )}
        </Form.Select>
      </Form.Group>

      {selectedAttribute != "random" ? (
        <Form.Group className="mb-2">
          <Form.Label>Type</Form.Label>
          <Form.Select
            className="select-sorting-type"
            isSearchable={false}
            value={selectedSortingType}
            onChange={onChangeSortingTypeButton}
          >
            {/* <option value="">Select Attributes</option> */}

            <option value="random">Diversify</option>
            <option value="uniformly">Uniformly</option>
          </Form.Select>
        </Form.Group>
      ) : (
        ""
      )}

      <button
        className="btn-brighter-primary shuffle-button mt-1"
        type="submit"
        onClick={onClickChooseClassShuffleButton}
        disabled={!(selectedClassId > 0)}
      >
        <img src={ShuffleIcon} className="shuffle-icon" width={20} />
        Shuffle
      </button>
    </div>
  );
}
