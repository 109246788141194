import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddClassModal,
  setLoginModal,
  setRegisterModal,
  setSaveClassModal,
} from "../../store/slices/modalSlice";
import { setloggedIn } from "../../store/slices/loggedInSlice";
import { Form } from "react-bootstrap";
import axios from "axios";
import {
  convertNamesToArray,
  getBaseApiURL,
  getToken,
} from "../../utils/classHelper";
import { setName } from "../../store/slices/userSlice";
import {
  setReloadClasses,
  setSelectedClass,
  setSelectedClassName,
} from "../../store/slices/classSlice";

export default function AddClassModal() {
  const dispatch = useDispatch();

  const showAddClass = useSelector((state) => state.modal.addClassShow);

  //console.log("showSaveClass", showAddClass);

  const [errorMessage, setErrorMessage] = useState();
  const [newClassName, setNewClassName] = useState("");

  useEffect(() => {
    if (showAddClass) setNewClassName("");
  }, [showAddClass]);

  const clickedCloseButton = () => {
    dispatch(setAddClassModal(false));
  };

  const clickedSaveClassButton = () => {
    let url = getBaseApiURL() + "/add-class";
    setErrorMessage("");

    axios
      .put(
        url,
        {
          name: newClassName,
        },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((response) => {
        dispatch(setAddClassModal(false));
        dispatch(setReloadClasses(true));
        dispatch(setSelectedClass(0));
        dispatch(setSelectedClassName(""));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            //console.log(error.response.data.errors[0]);
            setErrorMessage(error.response.data.errors[0]);
          }
        }
      });
  };

  return (
    <div>
      {" "}
      <Modal
        show={showAddClass}
        onHide={clickedCloseButton}
        backdrop="static"
        keyboard={false}
        className="custom-modal save-class-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="header">
              <h5 className="heading">Add Class</h5>
              <p className="description">{/* Enter Class Name */}</p>
            </div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="Enter Class Name"
                value={newClassName}
                onInput={(e) => setNewClassName(e.target.value)}
              />
            </Form.Group>

            <button
              className="btn-brighter-primary submit-button"
              type="button"
              onClick={clickedSaveClassButton}
            >
              Add Class{" "}
            </button>

            {errorMessage ? (
              <div className="error-message">{errorMessage}</div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
