import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddClassModal,
  setLoginModal,
  setRegisterModal,
  setSaveClassModal,
  setShareableGroupShow,
} from "../../store/slices/modalSlice";
import { setloggedIn } from "../../store/slices/loggedInSlice";
import { Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import {
  convertNamesToArray,
  getBaseApiURL,
  getToken,
} from "../../utils/classHelper";
import { setName } from "../../store/slices/userSlice";
import { setReloadClasses } from "../../store/slices/classSlice";
import copyWhiteIcon from "../../assets/img/copy-white.svg";
import EachSpecialBoxView from "../groups/templates/EachSpecialBoxView";
import { copyToClipboard } from "../../utils/commonHelper";

export default function ShareableGroupModal() {
  const dispatch = useDispatch();

  const [copyButtonText, setCopyButtonText] = useState("Copy URL");
  const [copyText, setCopyText] = useState();

  const specialGroups = useSelector((state) => state.group.specialGroups);
  // console.log("specialGroups", specialGroups);

  const shareableGroupShow = useSelector(
    (state) => state.modal.shareableGroupShow
  );
  const selectedClassName = useSelector(
    (state) => state.group.selectedClassName
  );
  const selectedClassShareableId = useSelector(
    (state) => state.group.selectedClassShareableId
  );
  const website_url = process.env.REACT_APP_WEBSITE_URL;

  //console.log("showSaveClass", showAddClass);

  const [errorMessage, setErrorMessage] = useState();
  const [newClassName, setNewClassName] = useState();

  const final_url = `${website_url}/group/${selectedClassShareableId}`;

  const clickedCopyButton = () => {

    copyToClipboard(final_url);
    // navigator.clipboard.writeText(final_url);
    setCopyButtonText("Copied");
    setTimeout(function () {
      setCopyButtonText("Copy URL");
    }, 500);
  };

  const clickedCloseButton = () => {
    dispatch(setShareableGroupShow(false));
  };

  return (
    <div>
      {" "}
      <Modal
        show={shareableGroupShow}
        onHide={clickedCloseButton}
        backdrop="static"
        keyboard={false}
        className="custom-modal full-screen  sharable-modal"
      >
        <Modal.Header closeButton>
          {" "}
          <h3 className="heading">{selectedClassName}</h3>
          <InputGroup className="mb-3 copy-field">
            <Form.Control value={final_url} />
            <Button
              variant="outline-secondary"
              onClick={clickedCopyButton}
            >
              <img src={copyWhiteIcon} width={30} /> {copyButtonText}
            </Button>
          </InputGroup>
        </Modal.Header>
        <Modal.Body>
          <div className="group-box">
            {specialGroups.map((eachGroup, index) => (
              <EachSpecialBoxView
                key={index}
                groupName={index + 1}
                eachGroup={eachGroup}
              ></EachSpecialBoxView>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
