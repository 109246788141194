import { createSlice } from "@reduxjs/toolkit";

export const classSlice = createSlice({
  name: "class",
  initialState: {
    classSelectedAttr: "",
    classSelectedId: 0,
    classSelectedName:'',
    studentsIdsToBeDeleted:[],
    reloadClasses:false,
    reloadStudents:false,
  },

  reducers: {
    setSelectedClass: (state, action) => {
      state.classSelectedId = action.payload;
    },
    setSelectedAttr: (state, action) => {
      state.classSelectedAttr = action.payload;
    },
    
    setReloadClasses: (state, action) => {
      state.reloadClasses = action.payload;
    },
    setReloadStudents: (state, action) => {
      state.reloadStudents = action.payload;
    },
    setSelectedClassName: (state, action) => {
      state.classSelectedName = action.payload;
    },
    setStudentIdstoBeDeleted: (state, action) => {
      state.studentsIdsToBeDeleted = action.payload;
    },
  },
});

export const {setSelectedClass, setSelectedAttr,setReloadClasses,setSelectedClassName,setStudentIdstoBeDeleted,setReloadStudents} =
  classSlice.actions;
export default classSlice.reducer;
