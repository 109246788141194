import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import checkGreen from "../assets/img/check-green.svg";
import groupPeopelTalking from "../assets/img/image-people-talking.png";

export default function Information() {
  return (
    <section className="information-section">
      <Container>
        <Row className="align-items-center">
          <Col lg={{span:6, order:1}} xs={{span:12, order:2}}>
            <div className="text-box">
              <h1>Not just another random grouping generator</h1>
              <p>
                A quick and efficient solution for grouping in your classroom or
                organization. Making groups is just a click away.
              </p>
              <ul>
                <li>
                  <img src={checkGreen} className="green-check" />
                  <p>Save your classes for later use</p>
                </li>

                <li>
                  <img src={checkGreen} className="green-check" />
                  <p>Share your groups with a link</p>
                </li>

                <li>
                  <img src={checkGreen} className="green-check" />
                  <p>Invite students to join with a class code</p>
                </li>

                <li>
                  <img src={checkGreen} className="green-check" />
                  <p>Add student attributes to make smart groups efficiently</p>
                </li>
              </ul>
              <p>Or just use it as a basic random group generator</p>
            </div>
          </Col>
          <Col lg={{span:6, order:2}} xs={{span:12, order:1}}>
            <img src={groupPeopelTalking} className="people-talking" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
