import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContainerSidebar from "./sidebar/ContainerSidebar";
import InviteStudentIcon from "../assets/img/add-icon.svg";
import ContainerGroups from "./groups/ContainerGroups";
import LoginModal from "./templates/LoginModal";
import RegisterModal from "./templates/RegisterModal";
import SaveClassModal from "./templates/SaveClassModal";
import { useDispatch, useSelector } from "react-redux";
import ContainerClasses from "./classes/ContainerClasses";
import AddClassModal from "./templates/AddClassModal";
import DeleteSelectedStudentsModal from "./templates/DeleteSelectedStudentsModal";
import EditClassAttributesModal from "./templates/EditClassAttributesModal";
import ShareableGroupModal from "./templates/ShareableGroupModal";
import TopNavbar from "./templates/TopNavbar";
import DeleteClassModal from "./templates/DeleteClassModal";
import RenameClassModal from "./templates/RenameClassModal";
import Information from "./Information";
import {
  setFeedbackModal,
  setInviteStudentsModal,
} from "../store/slices/modalSlice";
import InviteStudentModal from "./templates/InviteStudentsModal";
import {
  selectSidebarOption,
  selectSidebarSubOption,
} from "../store/slices/sidebarOptionSlice";
import feedbackIcon from "../assets/img/feedback.svg";
import FeedbackModal from "./templates/FeedbackModal";
import { Helmet } from "react-helmet";
import ForgotPasswordModal from "./templates/ForgotPasswordModal";

export default function Generator() {
  const dispatch = useDispatch();

  const sidebarOption = useSelector((state) => state.sidebarOption.option);
  const isLoggedIn = useSelector((state) => state.loggedIn.is_logged_in);

  const renderTitle = () => {
    if (sidebarOption == "group") return "Group Generator";
    else return "Classes";
  };

  const renderRightSide = () => {
    if (sidebarOption == "group") return <ContainerGroups></ContainerGroups>;
    else return <ContainerClasses></ContainerClasses>;
  };

  const clickedInviteStudentButton = () => {
    dispatch(selectSidebarOption("group"));
    dispatch(selectSidebarSubOption("text"));
    dispatch(setInviteStudentsModal(true));
  };

  const inviteStudentButton = () => {
    if (isLoggedIn)
      return (
        <button
          className="invite-students-button"
          onClick={clickedInviteStudentButton}
        >
          <img src={InviteStudentIcon} className="icon" />
        </button>
      );
  };

  const editAttributes = () => {
    // return "Edit Attributes";
  };
  const clickedFeedbackButton = () => {
    dispatch(setFeedbackModal(true));
  };

  return (
    <div>
       <Helmet>
        <title>Ready Group Go | Random Group Generator</title>
        <meta name="description" content="Elevate your teamwork and collaboration with our innovative Group Generator tool! Simply input the names of your students and specify the desired group size, and watch as our intelligent algorithm creates perfectly balanced and diverse groups." />
        <meta name="keywords" content="Random Group Generator, Group Generator, Student Group Generator, Team Formation Tool, Student Grouping, Collaborative Learning, Teamwork Facilitator, Automated Grouping, Efficient Group Formation, Classroom Management, Project Collaboration, Student Engagement Tool, Dynamic Team Building, Name-based Grouping, Balanced Group Assignments, Study Session Organizer, Simplified Group Creation" />
        <meta name="author" content="" />
        <meta property="og:title" content="Ready Group Go | Random Group Generator" />
        <meta property="og:description" content="Elevate your teamwork and collaboration with our innovative Group Generator tool! Simply input the names of your students and specify the desired group size, and watch as our intelligent algorithm creates perfectly balanced and diverse groups. " />
        <meta property="og:url" content="https://readygroupgo.com" />
        <meta name="twitter:title" content="Ready Group Go | Random Group Generator" />
        <meta name="twitter:description" content="Elevate your teamwork and collaboration with our innovative Group Generator tool! Simply input the names of your students and specify the desired group size, and watch as our intelligent algorithm creates perfectly balanced and diverse groups. " />
        <meta name="twitter:image" content="https://readygroupgo.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      
      <div className="feedback-button" onClick={clickedFeedbackButton}>
        <img src={feedbackIcon} className="icon" />
      </div>
      <TopNavbar></TopNavbar>
      <FeedbackModal></FeedbackModal>
      <RenameClassModal></RenameClassModal>
      <DeleteClassModal></DeleteClassModal>
      <LoginModal></LoginModal>
      <ForgotPasswordModal></ForgotPasswordModal>
      <RegisterModal></RegisterModal>
      <SaveClassModal></SaveClassModal>
      <AddClassModal></AddClassModal>
      <DeleteSelectedStudentsModal></DeleteSelectedStudentsModal>
      <EditClassAttributesModal></EditClassAttributesModal>
      <ShareableGroupModal></ShareableGroupModal>
      <InviteStudentModal></InviteStudentModal>
      <div className="generator-section">
        <Container>
          <div className="header-section">
            <h1 className="title">{renderTitle()}</h1>
            {sidebarOption == "group"
              ? inviteStudentButton()
              : editAttributes()}
          </div>
          <Row>
            <Col md="4" lg="3">
              <ContainerSidebar></ContainerSidebar>
            </Col>
            <Col md="8" lg="9">
              {renderRightSide()}
            </Col>
          </Row>
        </Container>
      </div>
      <Information></Information>
    </div>
  );
}
